import React, { Component } from 'react'
import styled from 'styled-components'
require('prismjs/themes/prism.css')

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const Body = styled.div`
  & {
    color: ${props => props.theme.colors.base};
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
  }

  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 19px;
    color: #747d8c;
  }

  p {
    margin: 0 0 2em 0;
    font-size: 18px;
    line-height: 1.75rem;
  }

  div.toc__wrap {
    padding: 1rem;
    margin-bottom: 1.5rem;
    background-color: ${props => props.theme.colors.tertiary};

    h2 {
      font-size: 22px;
      color: ${props => props.theme.colors.grayDark};
    }

    ul {
      margin: 0;
    }

    li {
      list-style: none;
    }

    a {
      color: ${props => props.theme.colors.grayDark};
    }
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    font-size: 18px;
    margin: 0 0 2em 0;
    padding-left: 1.5rem;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: outside;
      line-height: 1.5;
      padding-bottom: 0.5rem;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: outside;
      line-height: 1.5;
      &:last-child {
        margin: 0;
      }
    }
  }

  div.table__wrap {
    overflow-x: auto;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 2em;
  }

  table,
  th,
  td {
    border: 1px solid ${props => props.theme.colors.secondary};
    vertical-align: middle;
  }

  th,
  td {
    padding: 0.5em;
  }

  th {
    background-color: ${props => props.theme.colors.tertiary};
    font-weight: bold;
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 1em;
    color: ${props => props.theme.colors.grayDark};
    p {
      font-size: 16px;
    }
    a {
      color: ${props => props.theme.colors.grayDark};
    }
  }

  div.gatsby-highlight {
    margin-bottom: 2em;
  }
  *:not(pre) > code[class*='language-'] {
    padding: 0.1em 0.3em;
  }
  code[class*='language-'] {
    color: ${props => props.theme.colors.base};
    text-shadow: none;
    font-size: 0.9em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }

  @media screen and (max-width: 769px) {
    p {
      font-size: 16px;
      line-height: 1.5rem;
    }
    ul,
    ol {
      font-size: 16px;
    }
  }

  p#wrapper-cta-1 {
    box-shadow: 6px 6px 17px 0 rgba(82, 76, 76, 0.06);
    padding: 1rem;
    text-align: center;
    a.button-cta {
      min-width: 130px;
      padding: 12px 28px;
      border-radius: 4px;
      background-color: #e9f9f0;
      transition: all 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      color: #38cb89;
      font-size: 15px;
      font-weight: 500;
      &:hover {
        background-color: #dcf0e5;
        cursor: pointer;
      }
    }
  }
`

class PageBody extends Component {
  pageBodyId = 'post-body'

  componentDidMount() {
    this.addTargetBlank()
    this.createTOC()
    this.wrapTable()
  }

  addTargetBlank() {
    // 外部リンクを別タブで開く
    const links = document.querySelectorAll('a[href^=http]')
    for (let i = 0; i < links.length; ++i) {
      // （rel=noopener を指定することにより、必ず別プロセスで開く）
      links[i].setAttribute('target', '_blank')
      links[i].setAttribute('rel', 'noopener')
      links[i].setAttribute('rel', 'noreferrer')
    }
  }

  createTOC() {
    const postBodyId = this.pageBodyId
    const toc = document.getElementById('toc')
    if (!toc) return

    const headings = document.body.querySelectorAll(
      `div#${postBodyId} > h2, div#${postBodyId} > h3`
    )

    const list = document.createElement('ul')
    const listWrap = document.createElement('div')
    listWrap.setAttribute('class', 'toc__wrap')
    listWrap.innerHTML += `<h2>目次</h2>`
    listWrap.appendChild(list)
    toc.appendChild(listWrap)

    headings.forEach((heading, index) => {
      const listItem = document.createElement('li')
      const anchor = document.createElement('a')
      anchor.setAttribute('href', `#${heading.id}`)
      anchor.textContent = heading.textContent
      listItem.appendChild(anchor)

      if (heading.tagName === 'H2') {
        list.appendChild(listItem)
      } else if (heading.tagName === 'H3') {
        let nestedList = list.lastElementChild
        if (nestedList && nestedList.tagName !== 'UL') {
          nestedList = document.createElement('ul')
          list.appendChild(nestedList)
        }
        nestedList.appendChild(listItem)
      }
    })
  }

  wrapTable() {
    const postBodyId = this.pageBodyId
    const tables = document.body.querySelectorAll(`div#${postBodyId} > table`)
    if (tables.length === 0) return

    tables.forEach(table => {
      table.outerHTML = `<div class="table__wrap">` + table.outerHTML + '</div>'
    })
  }

  render() {
    return (
      <Wrapper>
        <Body
          id="post-body"
          dangerouslySetInnerHTML={{
            __html: this.props.body.childMarkdownRemark.html,
          }}
        />
      </Wrapper>
    )
  }
}

export default PageBody
